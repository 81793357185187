.project-details-wrapper {
  .details {
    background: radial-gradient(100.32% 543.88% at 100.32% 100.74%, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.1) 100%);
    backdrop-filter: blur(1px);
    border: 1px solid rgba(255, 255, 255, 0.4);

    .icon {
      width: 75px;
      height: 75px;
      background: rgba(255, 255, 255, 0.24);
      border-radius: 22px;
      display: inline-block;
      padding: 10px;
    }

    .social-links {
      height: 40px;
      width: 40px;
      color: white;
      font-size: 18px;
      display: flex;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.4);
      justify-content: center;
    }

    .project-name {
      font-weight: 600;
      font-size: 26px;
      line-height: 27px;
      letter-spacing: 0.1em;
      color: #ffffff;
    }

    .description {
      font-family: 'Public Sans';
      font-size: 16px;
      color: #FFFFFF;
      opacity: 0.8;
    }
  }

  .additional-details {
    .graph {
      margin-right: 3rem;
      width: 50%;
      img {
        width: 100%;
      }
    }

    .screening {
      background: radial-gradient(100.32% 543.88% at 100.32% 100.74%, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.1) 100%);
      backdrop-filter: blur(1px);
      border: 1px solid rgba(255, 255, 255, 0.4);
      width: calc(40% - 3rem);
      >div {
        margin-top: 1em;
        >p {
          color: white;
          line-height: 1em;
          margin-bottom: 0.8em;
          font-family: 'poppins';
          font-size: 24px;
        }
      }
    }
  }

  .progress-container {

    .progress-details span {
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 32px;
      letter-spacing: 0.1em;
      color: #ffffff;
    }

    .graph {
      background: radial-gradient(100.32% 543.88% at 100.32% 100.74%, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.1) 100%);
      backdrop-filter: blur(1px);
      border: 1px solid rgba(255, 255, 255, 0.4);
      margin-right: 3rem;
      width: 50%;

      span {
        font-family: 'poppins';
        font-size: 24px;
        color: #E8D33E;
        margin-top: 1em;
      }

      .MuiLinearProgress-root {
        height: 18px;
        background: rgba(232, 211, 61, 0.1);

        .MuiLinearProgress-bar {
          background-color: #E8D33E;
        }
      }
    }

    .screening {
      background: radial-gradient(100.32% 543.88% at 100.32% 100.74%, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.1) 100%);
      backdrop-filter: blur(1px);
      border: 1px solid rgba(255, 255, 255, 0.4);
      width: calc(40% - 3rem);
      text-align: center;

      .not-whitelisted {
        opacity: 1 !important;
      }

      .not-whitelisted,
      .investment-details {
        font-family: 'Barlow';
        font-size: 16px;
        text-align: center;
        color: #FFFFFF;
        opacity: 0.3;
        margin-bottom: 0;
      }

      .connect-button {
        background: #E8D33E;
        justify-content: center;
        height: 50px;

        p {
          font-family: 'Public Sans';
          font-weight: 700;
          font-size: 20px;
          color: #000000;
        }
      }

      .MuiButton-root {
        width: 100%;
        background: #E8D33E;
        text-align: center;
        margin-top: 1rem;
        font-family: 'Public Sans';
        font-weight: 700;
        font-size: 20px;
        color: #000000;
        &:hover {
          border: 1px solid transparent;
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.1em;
      color: #ffffff;
    }
  }

  .table-section {

    .tabs {
      border-bottom: transparent;

      button {
        font-family: "Barlow";
        font-size: 30px;
        color: #E8D33E;
        padding: 5px 10px;
      }

      .MuiTabs-indicator {
        background: #E8D33E;
      }
    }

    .spacer {
      width: 2rem;
    }

    .table-detail {
      background: radial-gradient(100.32% 543.88% at 100.32% 100.74%, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.1) 100%);
      backdrop-filter: blur(1px);
      border: 1px solid rgba(255, 255, 255, 0.4);
    }

    table {
      width: 100%;

      tr {
        height: 34px;
        border-bottom: 1px solid rgba(255, 255, 255, .15);
      }

      td {
        font-family: 'Barlow';
        font-size: 20px;
        color: #FFFFFF;
        padding: 10px 0;
      }

      th {
        font-family: 'Barlow';
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        padding: 10px 0;
      }
    }
  }

  .outline-input {
    width: 100%;

    input {
      color: white !important;
      padding: 0.5rem 1rem;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 18px !important;
      font-family: 'Barlow' !important;
    }

    .MuiButtonBase-root {
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      font-family: 'Barlow' !important;
      line-height: 12px !important;
      color: #ffffff !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
      border-bottom: 1px solid #ffffff !important;
    }

    legend {
      display: none;
    }
  }
}

@media (max-width: 1400px) {
  .graph {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .screening {
    width: 100% !important;
  }
}