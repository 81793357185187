.buttonOverride {
    position: relative;
    width: 460px;
    min-height: 65px;
    border-radius: 0 !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 120% !important;
    display: flex;
    align-items: center;
    text-align: center;
    box-shadow: none !important;
    animation: none !important;
    transition: all 0.32s ease !important;
    color: #000000 !important;
    box-shadow: 4.54874px 4.54874px 4.54874px rgba(0, 0, 0, 0.35), 6px 6px 0px rgba(255, 255, 255, 0.3) !important;
}

.buttonOverride:disabled {
    opacity: .5 !important;
    background: #FFFFFF !important;
}

.blCornor {
    height: 0;
    width: 0;
    position: absolute;
    transform: rotate(315deg);
    bottom: -20px;
    left: -4px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid black;
}

.trCornor {
    height: 0;
    width: 0;
    position: absolute;
    transform: rotate(315deg);
    top: -14px;
    right: -9px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid black;
}

.actionButtonText {
    text-transform: capitalize !important;
    font-weight: 700 !important;
    font-family: 'Public Sans' !important;
}