footer {
    padding-top: 1rem;
}

footer .logo {
    width: 200px;
    font-size: 35px !important;
    color: #FFFFFF !important;
}

footer .nav-link {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.1em;
    line-height: 32px;
}

.nav-link:hover,
.nav-link:focus {
    color: #E8D33E !important;
}

footer .list-group-item {
    background: transparent;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
}

.copyright {
    color: rgba(255, 255, 255, 0.3);
    font-size: 15px;
    letter-spacing: 0.1em;
    line-height: 32px;
    padding: 1rem;
    margin: 0;
    text-align: center;
    font-family: 'Public Sans';
}

@media (max-width: 768px) {
    footer.justify-content-between {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    footer.justify-content-between>* {
        display: flex;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}